import { Channel } from "../../../../../api/getChannels";
import { CreateChannelData } from "../../../../../api/createChannel";
import Button from "../../../../Button/Button";
import Form from "../../../../hoc/Form/Form";

import classes from "./ChannelForm.module.scss";

interface ChannelFormViewProps {
  data?: Channel;
  isAdmin: boolean;
  onSubmit: (data: CreateChannelData) => void;
  loading: boolean;
}

type FormExt = new () => Form<CreateChannelData>;
const FormComponent = Form as FormExt;

const ChannelFormView = (props: ChannelFormViewProps) => {

  return (
    <FormComponent
      config={{
        id: {
          label: 'Channel ID',
          required: false,
          valid: true,
          value: props.data && props.data.channelId,
          resolve: (value) => {
            return value.replace(' ', '');
          },
          inputProps: {
            type: 'text',
            name: 'channelId',
            disabled: !props.isAdmin,
          },
        },
        name: {
          label: 'Channel Name',
          required: true,
          value: props.data ? props.data.channelName : '',
          inputProps: {
            type: 'text',
            name: 'channelName',
            disabled: !props.isAdmin,
          },
        },
      }}
      onSubmit={(variables) => props.isAdmin ? props.onSubmit(variables) : null}
    >
      {({ fields, valid, triggerSubmit }) => (
        <div className={classes.wrap}>
          <h2>Channel Details</h2>
          <div className={classes.row}>
            {props.isAdmin && (
              <>
                {fields.id}
              </>
            )}
            {fields.name}
          </div>
          {props.isAdmin && (
            <div className={classes.submit}>
              <Button
                type="submit"
                onClick={triggerSubmit}
                disabled={!valid || props.loading}
                loading={props.loading}
                color="primary"
              >
                {props.data ? 'Update' : 'Create'}
              </Button>
            </div>
          )}
        </div>
      )}
    </FormComponent>
  )
}

export default ChannelFormView;