import API from "..";
import { Channel } from "../getChannels";

// TODO: Organise reused types
export type Role = 'admin' | 'channel-owner';

export interface GetSelfResponseData {
  id: string;
  email: string;
  role: Role;
  channels: Channel[];
}

export const getSelf = () => API.get<GetSelfResponseData>('/user/self');