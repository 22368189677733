import classes from "./CenterView.module.scss";

interface CenterViewProps {
  children: any
}

const CenterView = (props: CenterViewProps) => (
  <div className={classes.wrap}>
    <div className={classes.content}>
      {props.children}
    </div>
  </div>
)

export default CenterView;