import { AxiosResponse } from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

import { formatErrorResponse } from "../../../../../api";
import { Channel } from "../../../../../api/getChannels";
import { createChannel } from "../../../../../api/createChannel";
import { updateChannel } from "../../../../../api/updateChannel";
import { useUserContext } from "../../../../hoc/UserContext/UserContext";
import ChannelFormView from "./ChannelFormView";

import classes from "./ChannelForm.module.scss";

interface ChannelFormProps {
  data?: Channel;
  updateChannel: (data: Channel) => void;
}

const ChannelForm = (props: ChannelFormProps) => {
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      let response: AxiosResponse<any>;
      if (props.data) {
        response = await updateChannel(props.data.id, data);
      } else {
        response = await createChannel(data)
      }

      props.updateChannel({
        ...(props.data ? props.data : response.data),
        ...data,
      });
      toast.success(`Succesfully ${props.data ? 'updated' : 'created'} channel`);

    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }

    setLoading(false);
  }

  // We only need to display the channel name if the user is not
  if (user?.role !== 'admin') {
    return (
      <div className={classes.title}>
        <h1>{props.data?.channelName}</h1>
        <p>Logged in as: {user?.email}</p>
      </div>
    );
  }

  return (
    <ChannelFormView
      isAdmin={!!user && user.role === 'admin'}
      data={props.data}
      onSubmit={onSubmit}
      loading={loading}
    />
  )

}

export default ChannelForm;