import { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, TableFooter, TableHead } from '@material-ui/core';
import { Item, Menu } from 'react-contexify';
import { DataListContextMenuItem } from './DataList';

// TODO: Move context menu and style into seperate component
import "react-contexify/dist/ReactContexify.css";
import "./Overides.css";

import classes from './DataList.module.scss';

interface DataListViewProps {
  loading: boolean;
  data: any[];
  count: number;
  pageSize: number;
  page: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  columns: {
    id: string;
    label: string;
  }[];
  contextMenu?: DataListContextMenuItem[];
  openContextMenu?: (e, data: any) => void;
  refetch?: () => void;
}

const DataListView = (props: DataListViewProps) => {
  const emptyRows = props.pageSize - props.data.length;
  const [selectedRow, setSelectedRow] = useState();

  return (
    <div>
      {props.contextMenu && (
        <Menu id="DATA_LIST">
          {props.contextMenu.map((menuItem) => (
            <Item
              data={selectedRow}
              onClick={({ data }) => menuItem.action(data, props.refetch)}
            >
              {menuItem.label}
            </Item>
          ))}
        </Menu>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.header}>
            <TableRow key="header-row">
              {props.columns.map((column) => (
                <TableCell key={`header-${column.id}`}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.loading ? (
              <>
                {props.data.map((row, rowIndex) => (
                  <TableRow key={`row-${props.page}-${rowIndex}`}>
                    {props.columns.map((column) => (
                      <TableCell onClick={(e) => {
                        setSelectedRow(row);
                        props.openContextMenu && props.openContextMenu(e, row);
                      }}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow key="row-empty" style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={props.columns.length * 2} />
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow key="row-loading" style={{ height: 53 * props.pageSize }}>
                <TableCell align="center" colSpan={props.columns.length * 2} >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={props.columns.length}
                count={props.count || 0}
                page={props.page}
                onChangePage={(_, page) => {
                  console.log(page);
                  props.onPageChange(page)
                }}
                rowsPerPage={props.pageSize}
                onChangeRowsPerPage={(event) => {
                  const pageSize = parseInt(event.target.value, 10);
                  props.onPageSizeChange(pageSize);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DataListView;