import { useState } from "react";
import { Channel } from "../../api/getChannels";
import Button from "../../components/Button/Button";
import ChannelList from "../../components/Channel/ChannelList/ChannelList";
import ChannelSingle from "../../components/Channel/ChannelSingle/ChannelSingle";
import PageHeader from "../../components/hoc/PageHeader/PageHeader";
import PageView from "../../components/hoc/PageView/PageView";

interface ChannelsProps {}

const Channels = (props: ChannelsProps) => {
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [create, setCreate] = useState<boolean>(false);

  const onReturn = () => {
    setSelectedChannel(null);
    setCreate(false);
  }

  return (
    <PageView>
      {selectedChannel || create ? (
        <ChannelSingle
          data={selectedChannel || undefined}
          return={onReturn}
        />
      ): (
        <>
          <PageHeader>
            <h1>Channel Managment</h1>
            <Button onClick={() => setCreate(true)}>Create</Button>
          </PageHeader>
          <ChannelList
            selectChannel={setSelectedChannel}
          />
        </>
      )}
    </PageView>
  )
};

export default Channels;