import { useState } from "react";
import { toast } from "react-toastify";

import { formatErrorResponse } from "../../../../../api";
import { uploadProgramme } from "../../../../../api/uploadProgramme";
import ChannelUploadView from "./ChannelUploadView";

interface ChannelUploadProps {
  channelId: string;
}

const ChannelUpload = (props: ChannelUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onSubmit = async () => {
    setLoading(true);
    if (!selectedFile) return;
    try {
      const response = await uploadProgramme(props.channelId, selectedFile);
      if (response && response.status === 200) {
        toast.success('Schedule sucessfully imported');
      }
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
    setLoading(false);
    setSelectedFile(null);
  }

  return (
    <ChannelUploadView
      loading={loading}
      onSubmit={onSubmit}
      selectFile={setSelectedFile}
      selectedFile={selectedFile}
    />
  );
}

export default ChannelUpload;