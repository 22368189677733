import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { formatErrorResponse } from '../../../api';
import { deleteChannel } from '../../../api/deleteChannel';
import { Channel, getChannels } from '../../../api/getChannels';
import DataList from '../../DataList/DataList';
import Modal from '../../Modal/Modal';

interface ChannelListProps {
  selectChannel: (channel: Channel) => void;
}

const ChannelList = (props: ChannelListProps) => {
  const [deleteModalId, setDeleteModalId] = useState<string>();
  const refetchRef = useRef<() => void>();

  const onDelete = async () => {
    if (!deleteModalId) return;

    try {
      await deleteChannel(deleteModalId);
      refetchRef.current && refetchRef.current();
      toast.success('Succesfully delete channel');
      // Reset modal
      setDeleteModalId(undefined);
      refetchRef.current = undefined;
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
  }

  return (
    <>
      <Modal
        open={!!deleteModalId}
        onClose={() => setDeleteModalId(undefined)}
        buttons={[
          {
            color: 'primary',
            onClick: () => onDelete(),
            children: 'Delete',
          },
          {
            onClick: () => setDeleteModalId(undefined),
            children: 'Cancel',
          },
        ]}
      >
        <>
          <h1>Are you sure you want to delete this channel?</h1>
          <p>All associated channel data will also be deleted.</p>
        </>
      </Modal>
      <DataList
        columns={[
          {
            id: 'channelId',
            label: 'Channel Id',
          },
          {
            id: 'channelName',
            label: 'Channel Name',
          },
        ]}
        contextMenu={[
          {
            label: 'Edit',
            action: (data) => {
              props.selectChannel(data);
            },
          },
          {
            label: 'Delete',
            action: async (data, refetch) => {
              refetchRef.current = refetch;
              setDeleteModalId(data.id);
            },
          },
        ]}
        dataFetch={getChannels}
      />
    </>
  );
}

export default ChannelList;