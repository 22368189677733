import PrivateRoute from "../../components/hoc/PrivateRoute/PrivateRoute";
import { useUserContext } from "../../components/hoc/UserContext/UserContext"
import ChannelManagment from "../ChannelManagment/ChannelManagment";
import ChannelUser from "../ChannelUser/ChannelUser";

const UserRoutes = () => {
  const { user } = useUserContext();

  return (
    <>
      {user?.role === 'admin' ? (
        <>
        <PrivateRoute path='/' component={() => (
          <ChannelManagment />
        )} />
        <PrivateRoute path='/create' component={() => (
          <></>
        )} />
        </>
      ) : (
        <PrivateRoute path='/' component={() => (
          <ChannelUser />
        )} />
      )}
    </>
  );
}

export default UserRoutes;