import API from "..";

export const uploadProgramme = async (channelId: string, file: any) => {
  // Build form data for upload request
  const formData = new FormData();
  formData.append('channelId', channelId);
  formData.append('file', file);

  return API.post<boolean>('/programme/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}