import classes from "./PageView.module.scss";

interface PageViewProps {
  children: any
}

const PageView = (props: PageViewProps) => (
  <div className={classes.wrap}>
    <div className={classes.content}>
      {props.children}
    </div>
  </div>
)

export default PageView;