import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

export interface PrivateRouteReturn {
  routeProps: RouteComponentProps;
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('auth-token');

  return (
    <Route exact {...rest} render={props => (
      <>
        {token
          ? <Component routeProps={props} />
          : <Redirect to={{ pathname: '/login' }} />
        }
      </>
    )} />
  );
};

export default PrivateRoute;
