import Button from "../../../../Button/Button";

import classes from "./ChannelUpload.module.scss";

interface ChannelUploadViewProps {
  loading: boolean;
  onSubmit: () => void;
  selectFile: (file: File | null) => void;
  selectedFile: File | null;
}

const ChannelUploadView = (props: ChannelUploadViewProps) => (
  <form>
    <div className={classes.wrap}>
      <h2>Import Channel Data</h2>
      <div className={classes.fileInput}>
        <div>
          {props.selectedFile ? props.selectedFile.name : 'No file selected'}
        </div>

        <Button
          variant="contained"
          disabled={props.loading}
          component="label"
        >
          Select File
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            hidden
            onChange={(e) => props.selectFile(e.target.files && e.target.files[0])}
          />
        </Button>
      </div>

      <div className={classes.submit}>
        <Button
          disabled={!props.selectedFile || props.loading}
          onClick={() => props.onSubmit()}
          loading={props.loading}
          color="primary"
        >
          Import
        </Button>
      </div>
    </div>
  </form>
)

export default ChannelUploadView;