import API from "..";
import { Role } from "../getSelf";

export interface UpdateUserData {
  email?: string;
  password?: string;
  role?: Role;
  channels?: string[];
}

export const updateUser = async (id: string, data: UpdateUserData) => API.put<any>(`/user/${id}`, data);