import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { AUTH_TOKEN_KEY } from "../../utils/constants";
import { useUserContext } from "../hoc/UserContext/UserContext";

const Logout = () => {
  const history = useHistory();
  // const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const { user, clearUser } = useUserContext();

  const onLogout = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    clearUser && clearUser();

    history.push('/');
  }

  if (!user) return null;

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={() => onLogout()}
    >
      Logout
  </Button>
  );
}

export default Logout;