import { ModalProps as MaterialModalProps, Modal as MaterialModal } from "@material-ui/core";
import Button, { ButtonProps } from "../Button/Button";

import classes from "./Modal.module.scss";

interface ModalProps extends MaterialModalProps {
  buttons?: ButtonProps[];
}

const Modal = (props: ModalProps) => (
  <MaterialModal
    {...props}
  >
    <div className={classes.wrap}>
    {props.children}
    {(props.buttons && props.buttons.length) && (
      <div className={classes.buttonRow}>
        {props.buttons.map((button) => (
          <Button
            {...button}
          />
        ))}
      </div>
    )}
    </div>
  </MaterialModal>
)

export default Modal;

