import { TextField, TextFieldProps } from "@material-ui/core";
import { InputProps } from "./FormInterfaces";

import classes from "./Form.module.scss";

export interface InputComponentProps {
  inputProps: InputProps;
  value: string;
  onChange: (value: any) => void;
  label?: string;
  textFieldProps: TextFieldProps;
}

const Input = (props: InputComponentProps) => {
  switch (props.inputProps.type) {
    case 'text':
    case 'email':
    case 'password':
    case 'password_any':
    case 'textarea':
      return (
        <div className={classes.fieldWrap}>
          <div className={classes.label}>{props.label}</div>
          <TextField
            {...props.inputProps}
            value={props.value}
            variant="outlined"
            color="primary"
            onChange={e => props.onChange(e.target.value)}
            type={['password', 'password_any'].includes(props.inputProps.type) ? 'password' : undefined}
            className={classes.field}
            {...props.textFieldProps}
          />
        </div>
      );

    default:
      return null;
  }
}

export default Input;