import { Switch, Route, Router } from 'react-router-dom';
import { AppBar, createMuiTheme, ThemeProvider, Toolbar } from '@material-ui/core';

import Login from './pages/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { history } from '.';
import UserContextWrap from './components/hoc/UserContext/UserContext';
import UserRoutes from './pages/UserRoutes/UserRoutes';
import Logo from './assets/images/logo.png';
import classes from './App.module.scss';
import Logout from './components/Logout/Logout';

function App() {

  return (
    <>
      <ThemeProvider theme={createMuiTheme({
        palette: {
          primary: {
            main: '#f18a01',
          },
          secondary: {
            main: '#000000',
          },
        },
      })}>
        <Router history={history}>
          <UserContextWrap>
            <AppBar color="secondary" position="static">
              <Toolbar>
                <div className={classes.toolbar}>
                  <img alt="" height="80" width="102" src={Logo}></img>
                  <div className={classes.logout}><Logout /></div>
                </div>
              </Toolbar>
            </AppBar>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <UserRoutes />
            </Switch>
          </UserContextWrap>
        </Router>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
}

export default App;
