import { CreateUserData } from "../../../../../../../api/createUser";
import { User } from "../../../../../../../api/getChannelUsers";
import { UpdateUserData } from "../../../../../../../api/updateUser";
import Button from "../../../../../../Button/Button";
import Form from "../../../../../../hoc/Form/Form";
import { ConfigParams } from "../../../../../../hoc/Form/FormInterfaces";

import classes from "./UserForm.module.scss";

interface UserFormViewProps {
  onSubmit: (data: CreateUserData | UpdateUserData) => void;
  loading: boolean;
  data?: User;
}

type FormExt = new () => Form<CreateUserData | UpdateUserData>;
const FormComponent = Form as FormExt;

const UserFormView = (props: UserFormViewProps) => (
  <FormComponent
    config={{
      email: {
        label: 'Email',
        required: true,
        value: props.data && props.data.email,
        inputProps: {
          type: 'email',
          name: 'email',
        },
      },
      password: {
        label: 'Password',
        required: true,
        inputProps: {
          type: 'password',
          name: 'password',
        },
      },
      password_confirm: {
        label: 'Confirm Password',
        required: true,
        inputProps: {
          type: 'password',
          name: 'password_confirm',
        },
      },
    }}
    validateForm={(config, form) => {
      if ((config.password as ConfigParams).value !== (config.password_confirm as ConfigParams).value) {
        return false;
      }
      return !form.anyInvalid(config);
    }}
    reduceVariables={(config) => {
      return {
        email: (config.email as ConfigParams).value,
        password: (config.password as ConfigParams).value,
      };
    }}
    onSubmit={props.onSubmit}
  >
  {({ fields, valid, triggerSubmit }) => (
    <div className={classes.wrap}>
      <div className={classes.field}>{fields.email}</div>
      <div className={classes.field}>{fields.password}</div>
      <div className={classes.field}>{fields.password_confirm}</div>
      <div className={classes.submit}>
        <Button
          type="submit"
          color="primary"
          onClick={triggerSubmit}
          disabled={!valid || props.loading}
          loading={props.loading}
        >
          {props.data ? 'Update' : 'Add'}
        </Button>
      </div>
    </div>

  )}
  </FormComponent>
);

export default UserFormView;
