import { useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { toast } from 'react-toastify';

import { formatErrorResponse } from '../../api';
import { PaginatedRequest, PaginatedResponseData } from '../../api/types';
import DataListView from './DataListView';

export interface DataListContextMenuItem {
  label: string;
  action: (data: any, refetch?: () => void) => void;
};

interface DataListProps {
  dataFetch: PaginatedRequest<any>;
  columns: {
    id: string;
    label: string;
  }[];
  contextMenu?: DataListContextMenuItem[];
}


const DataList = (props: DataListProps) => {
  const [pagnination, setPagination] = useState<{ page: number, pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [data, setData] = useState<PaginatedResponseData<any>>();
  const [loading, setLoading] = useState<boolean>(false);

  const { show } = useContextMenu({
    id: 'DATA_LIST', // TODO: Randomly generate this
  })

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await props.dataFetch(pagnination);
      setData(response.data);
    } catch (e) {
      const message = formatErrorResponse(e);
      if (message) toast.error(message);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagnination]);

  const handlePageChange = (page: number) => {
    setPagination({
      ...pagnination,
      page,
    });
  }

  const handlePageSizeChange = (size: number) => {
    setPagination({
      pageSize: size,
      page: 0,
    });
  }

  const displayContextMenu = (e) => {
    show(e);
  }

  return (
    <DataListView
      loading={loading}
      data={(data && data.data) || []}
      count={(data && data.count) || 0}
      pageSize={pagnination.pageSize}
      page={pagnination.page}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      columns={props.columns}
      contextMenu={props.contextMenu}
      openContextMenu={props.contextMenu && displayContextMenu}
      refetch={fetchData}
    />
  );
}

export default DataList;