import { CircularProgress } from "@material-ui/core";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatErrorResponse } from "../../../api";
import { getSelf, GetSelfResponseData } from "../../../api/getSelf";
import CenterView from "../CenterView/CenterView";

export interface UserContextInterface {
  user: GetSelfResponseData | null;
  clearUser: (() => void) | null;
  getUser: (() => void) | null;
}

export const UserContext = createContext<UserContextInterface>({ user: null, clearUser: null, getUser: null });

interface UserContextProps {
  children: any;
}

const UserContextWrap = (props: UserContextProps) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<GetSelfResponseData | null>(null);

  const fetchSelf = async () => {
    setLoading(true);
    try {
      const response = await getSelf();
      if (response && response.status === 200) {
        setUser(response.data);
      }
    } catch (e) {
      console.log("🚀 ~ file: UserContext.tsx ~ line 33 ~ fetchSelf ~ e", e)
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchSelf();
  }, []);

  if (loading) {
    return(
      <CenterView>
        <CircularProgress />
      </CenterView>
    );
  }

  return (
    <UserContext.Provider value={{ user, clearUser: () => setUser(null), getUser: fetchSelf }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextWrap;

export const useUserContext = () => useContext(UserContext);