import API from "..";
import { Role } from "../getSelf";

export interface CreateUserData {
  email: string;
  password: string;
  role: Role;
  channels: string[];
}

export const createUser = async (data: CreateUserData) => API.post<string>('/user', data);