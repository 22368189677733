import { useState } from "react";
import { toast } from "react-toastify";
import { formatErrorResponse } from "../../../../../../../api";
import { createUser } from "../../../../../../../api/createUser";

import { User } from "../../../../../../../api/getChannelUsers";
import { updateUser } from "../../../../../../../api/updateUser";
import UserFormView from "./UserFormView";

interface UserFormProps {
  data?: User;
  channelId: string;
  onComplete?: (email: string, oldEmail?: string) => void;
  onError?: (error) => void;
}

const UserForm = (props: UserFormProps) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (_variables) => {
    setLoading(true);
    const variables = {
      ..._variables,
      role: 'channel-owner',
      channels: [props.channelId],
    };

    try {
      if (props.data) {
        await updateUser(props.data.id, variables);
      } else {
        await createUser(variables)
      }

      props.onComplete && props.onComplete(variables.email, props.data?.email);
    } catch (e) {
      props.onError && props.onError(e);
      const message = formatErrorResponse(e);
      if (message) toast.error(message);
    }
    setLoading(false);
  }

  return (
    <UserFormView
      onSubmit={onSubmit}
      data={props.data}
      loading={loading}
    />
  );
}

export default UserForm;

