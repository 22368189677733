import classes from "./PageHeader.module.scss";
import { ReactNode } from "react";

interface PageHeaderProps {
  children: ReactNode;
}

const PageHeader = (props: PageHeaderProps) => (
  <div className={classes.wrap}>
    {props.children}
  </div>
);

export default PageHeader;